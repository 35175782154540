.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 24px;
  user-select: none;
}

.goHome {
  position: relative;
  padding-top: 24px;
}

.textWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: -100px;
}

@media (max-width: 820px) {
  .textWrapper {
    top: -40px;
  }
}
